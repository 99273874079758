





































































































































































































































































































































































































.el-input-number {
  .el-input__inner {
    line-height: 40px;
    height: 40px;
  }
}

.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;

    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;

      .subject-title {
        .number {
          padding-left: 1rem;
        }
      }

      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;

        span {
          line-height: 2rem;
          width: 9.375rem;
        }
      }
    }
  }
}

.searchbox {
  padding: 0.9rem 0 0 1.775rem;

  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;

    &::before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -17px;
    }
  }
}

.tixing {
  font-size: 14px;
  height: fit-content;
  padding: 0.25rem;
  border-radius: 0.2rem;
}

.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}

.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}

.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}

.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}

.showtitle {
  color: #F56C6C;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.areaBox {
  .el-textarea {
    .el-textarea__inner {
      min-height: 150px !important;
      resize: none;
    }
  }
}
